$(document).foundation();

var wH = $( '.content__section-cover' ).height();
$( '.orbit-container' ).height(wH);
$( '.orbit-slide' ).css( "max-height", wH );

$(window).resize(function() {
    var wH = $( '.content__section-cover' ).height();
    $( '.orbit-container' ).height(wH);
    $( '.orbit-slide' ).css( "max-height", wH );
});
